import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-body p-10 p-lg-15" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Overview = _resolveComponent("Overview")
  const _component_BasicExample = _resolveComponent("BasicExample")
  const _component_ContentBlocking = _resolveComponent("ContentBlocking")
  const _component_CardBlocking = _resolveComponent("CardBlocking")
  const _component_ModalBlocking = _resolveComponent("ModalBlocking")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_Overview),
      _createVNode(_component_BasicExample),
      _createVNode(_component_ContentBlocking),
      _createVNode(_component_CardBlocking),
      _createVNode(_component_ModalBlocking)
    ])
  ]))
}